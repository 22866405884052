<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Thank you!</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class="text-center body-1">
              Thank you for registrating with Sprout Management Console. You
              will receive an email to confirm your address.
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
